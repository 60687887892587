import { createRouter, createWebHistory } from 'vue-router';
import { defineAsyncComponent } from 'vue'
const Missing = defineAsyncComponent(() => import('../components/Missing.vue'));
import {AuthGuard, CustomerAuthGuard} from '../helper/authGuard/AuthGuard';
import store from '../store';

const routes = [
  /** User Portal Routes **/
  {
    //path: '/v3/user-login',
    path: '/login',
    name: 'UserPortalLogin',
    component: (() => defineAsyncComponent(() => import('../components/userPortal/pages/Login.vue')))(),
    meta: { title: 'User Portal - TruAbilities Customer Portal' }
  },
  {
    //path: '/v3/user-login',
    path: '/login/:id',
    name: 'UserWpPortalLogin',
    // component: () => defineAsyncComponent(() => import('../components/userPortal/pages/Login.vue')),
    component: (() => defineAsyncComponent(() => import('../components/userPortal/pages/Login.vue')))(),
    props: true,
    meta: { title: 'User Portal - TruAbilities Customer Portal' }
  },
  {
    //path: '/v3/user-verify',
    path: '/verify/:id',
    name: 'UserPortalVerifyUser',
    // component: () => defineAsyncComponent(() => import('../components/userPortal/pages/Login.vue')),
    component: (() => defineAsyncComponent(() => import('../components/userPortal/pages/Login.vue')))(),
    props: true,
    meta: { title: 'User Portal - TruAbilities Customer Portal' }
  },
  {
    path: '/forget-password',
    name: 'UserPortalForgetPassword',
    // component: () => defineAsyncComponent(() => import('../components/userPortal/pages/ForgetPassword.vue')),
    component: (() => defineAsyncComponent(() => import('../components/userPortal/pages/ForgetPassword.vue')))(),
    meta: { title: 'Forget Password - TruAbilities Customer Portal' }
  },
  {
    path: "/customer",
    name: 'CustomerLayout',
    component: (() => defineAsyncComponent(() => import('../components/userPortal/userPortalLayout.vue')))(),
    beforeEnter: CustomerAuthGuard,
    children: [
      {
        path: "dashboard",
        name: "CustomerDashboard",
        component: (() => defineAsyncComponent(() => import('../components/userPortal/pages/Dashboard.vue')))(),
        meta: { title: 'Dashboard - TruAbilities Customer Portal' }
      },
      {
        path: "add-website",
        name: "CustomerAddWebsite",
        // component: () => defineAsyncComponent(() => import('../components/userPortal/pages/addEditSite/addEditSite.vue')),
        component: (() => defineAsyncComponent(() => import('../components/userPortal/pages/addEditSite/addEditSite.vue')))(),
        meta: { title: 'Add New Website - TruAbilities Customer Portal' }
      },
      {
        path: "edit-website",
        name: "CustomerEditWebsite",
        props: true,
        // component: () => defineAsyncComponent(() => import('../components/userPortal/pages/addEditSite/addEditSite.vue')),
        component: (() => defineAsyncComponent(() => import('../components/userPortal/pages/addEditSite/addEditSite.vue')))(),
        meta: { title: 'Add New Website - TruAbilities Customer Portal' }
      },
      {
        path: "additional-services",
        name: "CustomerAdditionalService",
        props: true,
        // component: () => defineAsyncComponent(() => import('../components/userPortal/pages/addEditSite/addEditSite.vue')),
        component: (() => defineAsyncComponent(() => import('../components/userPortal/pages/addEditSite/addEditSite.vue')))(),
        meta: { title: 'Additional Service - TruAbilities Customer Portal' }
      },
      {
        path: "website-listing",
        name: "CustomerWebsiteListing",
        // component: () => defineAsyncComponent(() => import('../components/userPortal/pages/manageExistingSite/manageExistingSite.vue')),
        component: (() => defineAsyncComponent(() => import('../components/userPortal/pages/manageExistingSite/manageExistingSite.vue')))(),
        meta: { title: 'Website Listing - TruAbilities Customer Portal' }
      },
      {
        path: "branding-listing",
        name: "CustomerBrandingList",
        // component: () => defineAsyncComponent(() => import('../components/userPortal/pages/manageBranding/ManageBranding.vue')),
        component: (() => defineAsyncComponent(() => import('../components/userPortal/pages/manageBranding/ManageBranding.vue')))(),
        meta: { title: 'Branding List - TruAbilities Customer Portal' }
      },
      {
        path: "add-branding",
        name: "CustomerAddBranding",
        // component: () => defineAsyncComponent(() => import('../components/userPortal/pages/manageBranding/AddEditBranding.vue')),
        component: (() => defineAsyncComponent(() => import('../components/userPortal/pages/manageBranding/AddEditBranding.vue')))(),
        meta: { title: 'Branding Add - TruAbilities Customer Portal' }
      },
      {
        path: "edit-branding",
        name: "CustomerEditBranding",
        props: true,
        // component: () => defineAsyncComponent(() => import('../components/userPortal/pages/manageBranding/AddEditBranding.vue')),
        component: (() => defineAsyncComponent(() => import('../components/userPortal/pages/manageBranding/AddEditBranding.vue')))(),
        meta: { title: 'Branding Edit - TruAbilities Customer Portal' }
      },
      {
        path: "invoice-list",
        name: "CustomerInvoiceList",
        props: true,
        // component: () => defineAsyncComponent(() => import('../components/userPortal/pages/invoice/Invoice.vue')),
        component: (() => defineAsyncComponent(() => import('../components/userPortal/pages/invoice/Invoice.vue')))(),
        meta: { title: 'Invoice List - TruAbilities Customer Portal' }
      },
      {
        path: "invoice-details",
        name: "CustomerInvoiceDetails",
        props: true,
        // component: () => defineAsyncComponent(() => import('../components/userPortal/pages/invoice/InvoiceDetails.vue')),
        component: (() => defineAsyncComponent(() => import('../components/userPortal/pages/invoice/InvoiceDetails.vue')))(),
        meta: { title: 'Invoice Details - TruAbilities Customer Portal' }
      },
      {
        path: "monitoring",
        name: "CustomerMonitoring",
        props: true,
        // component: () => defineAsyncComponent(() => import('../components/userPortal/pages/monitor/monitor.vue')),
        component: (() => defineAsyncComponent(() => import('../components/userPortal/pages/monitor/monitor.vue')))(),
        meta: { title: 'Monitoring Details - TruAbilities Customer Portal' }
      },
      {
        path: "billing-details",
        name: "CustomerBillingDetails",
        props: true,
        // component: () => defineAsyncComponent(() => import('../components/userPortal/pages/billingManage/BillingManage.vue')),
        component: (() => defineAsyncComponent(() => import('../components/userPortal/pages/billingManage/BillingManage.vue')))(),
        meta: { title: 'Billing Details - TruAbilities Customer Portal' }
      },
      {
        path: "scanning",
        name: "CustomerAccessibilityScan",
        props: true,
        // component: () => defineAsyncComponent(() => import('../components/userPortal/pages/accessibilityScan/AccessibilityScan.vue')),
        component: (() => defineAsyncComponent(() => import('../components/userPortal/pages/accessibilityScan/AccessibilityScan.vue')))(),
        meta: { title: 'Accessibility Scan - TruAbilities Customer Portal' }
      },
      {
        path: "privacyform-leads",
        name: "CustomerPrivacyFormLeads",
        props: true,
        // component: () => defineAsyncComponent(() => import('../components/userPortal/pages/privacyFormLeads/privacyFormLeads.vue')),
        component: (() => defineAsyncComponent(() => import('../components/userPortal/pages/privacyFormLeads/privacyFormLeads.vue')))(),
        meta: { title: 'Privacy Form Leads - TruAbilities Customer Portal' }
      },
      {
        path: "view-scann",
        name: "CustomerScaningReport",
        props: true,
        // component: () => defineAsyncComponent(() => import('../components/userPortal/pages/accessibilityScan/ScanReport.vue')),
        component: (() => defineAsyncComponent(() => import('../components/userPortal/pages/accessibilityScan/ScanReport.vue')))(),
        meta: { title: 'Accessibility Scan Report - TruAbilities Customer Portal' }
      },
      {
        path: "view-violation",
        name: "CustomerViolationReport",
        props: true,
        // component: () => defineAsyncComponent(() => import('../components/userPortal/pages/accessibilityScan/ViolationReport.vue')),
        component: (() => defineAsyncComponent(() => import('../components/userPortal/pages/accessibilityScan/ViolationReport.vue')))(),
        meta: { title: 'Violation Report - TruAbilities Customer Portal' }
      },
      {
        path: "support-videos",
        name: "CustomerSupportVideo",
        // component: () => defineAsyncComponent(() => import('../components/userPortal/pages/supportVideos/supportVideos.vue')),
        component: (() => defineAsyncComponent(() => import('../components/userPortal/pages/supportVideos/supportVideos.vue')))(),
        meta: { title: 'Support Video - TruAbilities Customer Portal' }
      },
      {
        path: "getProfile",
        name: "CustomerProfile",
        // component: () => defineAsyncComponent(() => import('../components/userPortal/pages/profile/profile.vue')),
        component: (() => defineAsyncComponent(() => import('../components/userPortal/pages/profile/profile.vue')))(),
        meta: { title: 'Profile - TruAbilities Customer Portal' }
      },
      {
        path: "change-password",
        name: "CustomerChangePassword",
        // component: () => defineAsyncComponent(() => import('../components/userPortal/pages/changePassword/ChangePassword.vue')),
        component: (() => defineAsyncComponent(() => import('../components/userPortal/pages/changePassword/ChangePassword.vue')))(),
        meta: { title: 'Change Password - TruAbilities Customer Portal' }
      },
      {
        path: "settings",
        name: "CustomerUserSettings",
        // component: () => defineAsyncComponent(() => import('../components/userPortal/pages/userSettings/userSettings.vue')),
        component: (() => defineAsyncComponent(() => import('../components/userPortal/pages/userSettings/userSettings.vue')))(),
        meta: { title: 'User Settings - TruAbilities Customer Portal' }
      },
      {
        path: "support-tickets",
        name: "CustomerSupportTickets",
        // component: () => defineAsyncComponent(() => import('../components/userPortal/pages/supportTickets/SupportTickets.vue')),
        component: (() => defineAsyncComponent(() => import('../components/userPortal/pages/supportTickets/SupportTickets.vue')))(),
        meta: { title: 'Support Tickets - TruAbilities Customer Portal' }
      },
      {
        path: "website-logs",
        name: "WebsiteLogs",
        // component: () => defineAsyncComponent(() => import('../components/userPortal/pages/websiteScanLogs/WebsiteScanLogs.vue')),
        component: (() => defineAsyncComponent(() => import('../components/userPortal/pages/websiteScanLogs/WebsiteScanLogs.vue')))(),
        meta: { title: 'Website Scan Logs - TruAbilities Customer Portal' }
      },
      {
        path: "user-notification",
        name: "userNotificationAll",
        // component: () => defineAsyncComponent(() => import('../components/userPortal/pages/websiteScanLogs/WebsiteScanLogs.vue')),
        component: (() => defineAsyncComponent(() => import('../components/userPortal/pages/userNotification/userNotification.vue')))(),
        meta: { title: 'All Notifications - TruAbilities Customer Portal' }
      }
    ]
  },
  /** User Portal Routes **/

  
  /** Super Admin Routes **/
  {
    //path: '/v3/',
    path: '/console',
    name: 'SuperAdminLogin',
    // component: () => defineAsyncComponent(() => import('../components/superAdmin/pages/Login.vue')),
    component: (() => defineAsyncComponent(() => import('../components/superAdmin/pages/Login.vue')))(),
    meta: { title: 'Login - TruAbilities Admin' }
  },
  {
    path: "/admin",
    name: 'AdminLayout',
    // component: () => defineAsyncComponent(() => import('../components/superAdmin/SuperAdminLayout.vue')),
    component: (() => defineAsyncComponent(() => import('../components/superAdmin/SuperAdminLayout.vue')))(),
    beforeEnter: AuthGuard,
    children: [
      {
        path: "dashboard",
        name: "SuperAdminDashboard",
        // component: () => defineAsyncComponent(() => import('../components/superAdmin/pages/Dashboard.vue')),
        component: (() => defineAsyncComponent(() => import('../components/superAdmin/pages/Dashboard.vue')))(),
        meta: { title: 'Dashboard - TruAbilities Admin' }
      },
      {
        path: "media",
        name: "SuperAdminMedia",
        // component: () => defineAsyncComponent(() => import('../components/superAdmin/pages/Media.vue')),
        component: (() => defineAsyncComponent(() => import('../components/superAdmin/pages/Media.vue')))(),
        meta: { title: 'Media - TruAbilities Admin' }
      },
      {
        path: "active-websites",
        name: "SuperAdminActiveWebsites",
        // component: () => defineAsyncComponent(() => import('../components/superAdmin/pages/reports/activeSites.vue')),
        component: (() => defineAsyncComponent(() => import('../components/superAdmin/pages/reports/activeSites.vue')))(),
        meta: { title: 'Active Websites - TruAbilities Admin' }
      },
      {
        path: "deactive-websites",
        name: "SuperAdminDeactiveWebsites",
        // component: () => defineAsyncComponent(() => import('../components/superAdmin/pages/reports/deactiveSites.vue')),
        component: (() => defineAsyncComponent(() => import('../components/superAdmin/pages/reports/deactiveSites.vue')))(),
        meta: { title: 'Deactive Websites - TruAbilities Admin' }
      },
      // {
      //   path: "delete-media",
      //   name: "SuperAdminMediaDelete",
      //   component: () => defineAsyncComponent(() => import('../components/superAdmin/pages/Media.vue')),
      //   meta: { title: 'Delete Media - TruAbilities Admin' }
      // },
      {
        path: "customer-list",
        name: "SuperAdminCustomerList",
        // component: () => defineAsyncComponent(() => import('../components/superAdmin/pages/customer/CustomerList')),
        component: (() => defineAsyncComponent(() => import('../components/superAdmin/pages/customer/CustomerList')))(),
        meta: { title: 'Customer Mangament - TruAbilities Admin' }
      },
      {
        path: "website-list",
        name: "SuperAdminWebsiteList",
        props: true,
        // component: () => defineAsyncComponent(() => import('../components/superAdmin/pages/website/WebsiteList')),
        component: (() => defineAsyncComponent(() => import('../components/superAdmin/pages/website/WebsiteList')))(),
        meta: { title: 'Website Mangament - TruAbilities Admin' }
      },
      {
        path: "website-list/free",
        name: "SuperAdminFreeWebsiteList",
        props: true,
        // component: () => defineAsyncComponent(() => import('../components/superAdmin/pages/website/freeWebsiteList')),
        component: (() => defineAsyncComponent(() => import('../components/superAdmin/pages/website/freeWebsiteList')))(),
        meta: { title: 'Free Website Mangament - TruAbilities Admin' }
      },
      {
        path: "website-hits",
        name: "SuperAdminWebsiteHits",
        props: true,
        // component: () => defineAsyncComponent(() => import('../components/superAdmin/pages/website/WebsiteHits')),
        component: (() => defineAsyncComponent(() => import('../components/superAdmin/pages/website/WebsiteHits')))(),
        meta: { title: 'Website Hits Mangament - TruAbilities Admin' }
      },
      {
        path: "website-cancel",
        name: "SuperAdminWebsiteCancel",
        props: true,
        // component: () => defineAsyncComponent(() => import('../components/superAdmin/pages/website/WebsiteCancellation')),
        component: (() => defineAsyncComponent(() => import('../components/superAdmin/pages/website/WebsiteCancellation')))(),
        meta: { title: 'Website Cancel Mangament - TruAbilities Admin' }
      },
      {
        path: "scan-list",
        name: "SuperAdminAccessabilityScanList",
        props: true,
        // component: () => defineAsyncComponent(() => import('../components/superAdmin/pages/accessabilityScan/accessabilityScanList')),
        component: (() => defineAsyncComponent(() => import('../components/superAdmin/pages/accessabilityScan/accessabilityScanList')))(),
        meta: { title: 'Scan Mangament - TruAbilities Admin' }
      },
      {
        path: "scansettings-list",
        name: "SuperAdminAccessabilityScanSettingsList",
        props: true,
        // component: () => defineAsyncComponent(() => import('../components/superAdmin/pages/accessabilityScan/accessabilityScanSettingsList')),
        component: (() => defineAsyncComponent(() => import('../components/superAdmin/pages/accessabilityScan/accessabilityScanSettingsList')))(),
        meta: { title: 'Scan Settings - TruAbilities Admin' }
      },
      {
        path: "profile",
        name: "SuperAdminMemberProfile",
        // component: () => defineAsyncComponent(() => import('../components/superAdmin/pages/myAccount/Profile.vue')),
        component: (() => defineAsyncComponent(() => import('../components/superAdmin/pages/myAccount/Profile.vue')))(),
        meta: { title: 'User Profile - TruAbilities Admin' }
      },
      {
        path: "role-management",
        name: "SuperAdminRoleManage",
        // component: () => defineAsyncComponent(() => import('../components/superAdmin/pages/rolesPrivileges/RoleManagement.vue')),
        component: (() => defineAsyncComponent(() => import('../components/superAdmin/pages/rolesPrivileges/RoleManagement.vue')))(),
        meta: { title: 'Role Manage - TruAbilities Admin' }
      },
      {
        path: "user-management",
        name: "SuperAdminUserManage",
        // component: () => defineAsyncComponent(() => import('../components/superAdmin/pages/rolesPrivileges/UserManagement.vue')),
        component: (() => defineAsyncComponent(() => import('../components/superAdmin/pages/rolesPrivileges/UserManagement.vue')))(),
        meta: { title: 'User Manage - TruAbilities Admin' }
      },
      {
        path: "package-management",
        name: "SuperAdminPackageList",
        // component: () => defineAsyncComponent(() => import('../components/superAdmin/pages/package/PackageList')),
        component: (() => defineAsyncComponent(() => import('../components/superAdmin/pages/package/PackageList')))(),
        meta: { title: 'Package List - TruAbilities Admin' }
      },
      {
        path: "package-type",
        name: "SuperAdminPackageType",
        // component: () => defineAsyncComponent(() => import('../components/superAdmin/pages/package/PackageType')),
        component: (() => defineAsyncComponent(() => import('../components/superAdmin/pages/package/PackageType')))(),
        meta: { title: 'Package Type - TruAbilities Admin' }
      },
      {
        path: "package-feature",
        name: "SuperAdminPackageFeatures",
        // component: () => defineAsyncComponent(() => import('../components/superAdmin/pages/package/PackageFeatures')),
        component: (() => defineAsyncComponent(() => import('../components/superAdmin/pages/package/PackageFeatures')))(),
        meta: { title: 'Package Features - TruAbilities Admin' }
      },
      {
        path: "order-list",
        name: "SuperAdminOrderList",
        // component: () => defineAsyncComponent(() => import('../components/superAdmin/pages/order/OrderList')),
        component: (() => defineAsyncComponent(() => import('../components/superAdmin/pages/order/OrderList')))(),
        meta: { title: 'Orders - TruAbilities Admin' }
      },
      {
        path: "free-order-list",
        name: "SuperAdminFreeOrderList",
        // component: () => defineAsyncComponent(() => import('../components/superAdmin/pages/order/freeWebsiteOrderList')),
        component: (() => defineAsyncComponent(() => import('../components/superAdmin/pages/order/freeWebsiteOrderList')))(),
        meta: { title: 'Free Orders - TruAbilities Admin' }
      },
      {
        path: "languages",
        name: "SuperAdminLanguages",
        // component: () => defineAsyncComponent(() => import('../components/superAdmin/pages/widgetManagement/Languages')),
        component: (() => defineAsyncComponent(() => import('../components/superAdmin/pages/widgetManagement/Languages')))(),
        meta: { title: 'Languages - TruAbilities Admin' }
      },
      {
        path: "language-key",
        name: "SuperAdminLanguageKey",
        // component: () => defineAsyncComponent(() => import('../components/superAdmin/pages/widgetManagement/LanguageKey')),
        component: (() => defineAsyncComponent(() => import('../components/superAdmin/pages/widgetManagement/LanguageKey')))(),
        meta: { title: 'Language Key - TruAbilities Admin' }
      },
      {
        path: "language-text",
        name: "SuperAdminLanguageText",
        // component: () => defineAsyncComponent(() => import('../components/superAdmin/pages/widgetManagement/LanguageText')),
        component: (() => defineAsyncComponent(() => import('../components/superAdmin/pages/widgetManagement/LanguageText')))(),
        meta: { title: 'Language Text - TruAbilities Admin' }
      },
      {
        path: "device-info",
        name: "SuperAdminDeviceInfo",
        // component: () => defineAsyncComponent(() => import('../components/superAdmin/pages/widgetManagement/DeviceInfo.vue')),
        component: (() => defineAsyncComponent(() => import('../components/superAdmin/pages/widgetManagement/DeviceInfo.vue')))(),
        meta: { title: 'Device Info - TruAbilities Admin' }
      },
      {
        path: "leads",
        name: "SuperAdminLeads",
        // component: () => defineAsyncComponent(() => import('../components/superAdmin/pages/widgetManagement/Leads.vue')),
        component: (() => defineAsyncComponent(() => import('../components/superAdmin/pages/widgetManagement/Leads.vue')))(),
        meta: { title: 'Device Info - TruAbilities Admin' }
      },
      {
        path: "pages",
        name: "SuperAdminPages",
        // component: () => defineAsyncComponent(() => import('../components/superAdmin/pages/widgetManagement/websiteContent.vue')),
        component: (() => defineAsyncComponent(() => import('../components/superAdmin/pages/widgetManagement/websiteContent.vue')))(),
        meta: { title: 'Website Content Info - TruAbilities Admin' }
      },
      {
        path: "general-settings",
        name: "SuperAdminGeneralSettings",
        // component: () => defineAsyncComponent(() => import('../components/superAdmin/pages/settings/generalSettings.vue')),
        component: (() => defineAsyncComponent(() => import('../components/superAdmin/pages/settings/generalSettings.vue')))(),
        meta: { title: 'General Settings - TruAbilities Admin' }
      },
      {
        path: "email-type",
        name: "SuperAdminEmailType",
        // component: () => defineAsyncComponent(() => import('../components/superAdmin/pages/settings/emailType.vue')),
        component: (() => defineAsyncComponent(() => import('../components/superAdmin/pages/settings/emailType.vue')))(),
        meta: { title: 'Email Type - TruAbilities Admin' }
      },
      {
        path: "email-template",
        name: "SuperAdminEmailTemplate",
        // component: () => defineAsyncComponent(() => import('../components/superAdmin/pages/settings/emailTemplate')),
        component: (() => defineAsyncComponent(() => import('../components/superAdmin/pages/settings/emailTemplate')))(),
        meta: { title: 'Email Template - TruAbilities Admin' }
      },
      {
        path: "email-recipients",
        name: "SuperAdminEmailRecipients",
        // component: () => defineAsyncComponent(() => import('../components/superAdmin/pages/settings/emailRecipients')),
        component: (() => defineAsyncComponent(() => import('../components/superAdmin/pages/settings/emailRecipients')))(),
        meta: { title: 'Email Recipients - TruAbilities Admin' }
      },
      {
        path: "couchdb",
        name: "SuperAdminCouchDB",
        // component: () => defineAsyncComponent(() => import('../components/superAdmin/pages/settings/CounchDB.vue')),
        component: (() => defineAsyncComponent(() => import('../components/superAdmin/pages/settings/CounchDB.vue')))(),
        meta: { title: 'CouncDB - TruAbilities Admin' }
      },
      {
        path: "delete-couchdb",
        name: "SuperAdminDeleteCouchDB",
        // component: () => defineAsyncComponent(() => import('../components/superAdmin/views/dbActionArea.vue')),
        component: (() => defineAsyncComponent(() => import('../components/superAdmin/views/dbActionArea.vue')))(),
        meta: { title: 'CouncDB Delete- TruAbilities Admin' }
      },
      {
        path: "coupon",
        name: "SuperAdminCoupon",
        // component: () => defineAsyncComponent(() => import('../components/superAdmin/pages/promotion/Coupon.vue')),
        component: (() => defineAsyncComponent(() => import('../components/superAdmin/pages/promotion/Coupon.vue')))(),
        meta: { title: 'Coupon Management - TruAbilities Admin' }
      },
      {
        path: "cookie",
        name: "SuperAdminCookieManagement",
        // component: () => defineAsyncComponent(() => import('../components/superAdmin/pages/services/CookieManegement.vue')),
        component: (() => defineAsyncComponent(() => import('../components/superAdmin/pages/services/CookieManegement.vue')))(),
        meta: { title: 'Cookie Management - TruAbilities Admin' }
      },
      {
        path: "cookie-category",
        name: "SuperAdminCookieCategory",
        // component: () => defineAsyncComponent(() => import('../components/superAdmin/pages/services/CookieCategory.vue')),
        component: (() => defineAsyncComponent(() => import('../components/superAdmin/pages/services/CookieCategory.vue')))(),
        meta: { title: 'Cookie Category - TruAbilities Admin' }
      },
      {
        path: "pdf-template",
        name: "SuperAdminPdfTemplate",
        // component: () => defineAsyncComponent(() => import('../components/superAdmin/pages/settings/pdftemplate.vue')),
        component: (() => defineAsyncComponent(() => import('../components/superAdmin/pages/settings/pdftemplate.vue')))(),
        meta: { title: 'Pdf Template - TruAbilities Admin' }
      },
    ]
  },
  /** Super Admin Routes **/
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../components/About.vue')
  // }
  {
    path: "/:catchAll(.*)",
    component: Missing
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  if(to.path === '/v3/customer/dashboard'){
    store.dispatch('fullHeaderAC',{
      value: false
    });
  } else if (to.path === '/console' && window.localStorage.getItem('admin-token')){
    router.push({name: "SuperAdminDashboard"});
  } else if (to.path === '/login' && window.localStorage.getItem('customer-token')){
    router.push({name: "CustomerDashboard"});
  } else {
    store.dispatch('fullHeaderAC',{
      value: true
    });
  }
  document.title = to.meta.title;
  next();
});

export default router
